import React, { useContext, useEffect, useState } from 'react';
import {Switch, Route, Redirect, generatePath} from 'react-router-dom';
import asyncLoadingPage from 'components/asyncLoadingComponent.js';
import routes from 'globals/routes';
import Menu from 'containers/menu';

import './App.css';
import dataContext from 'contexts/data/dataContext';

const getRenderPropForRoute = (pageId) => {
  const AppComponent = asyncLoadingPage(pageId);
  // const AppComponent = templates[pageId];
  return (match) => { return (<AppComponent match={match} />) };
  
}
const App = () => {
  const {language, page, artist, setLanguage, setPage, setArtist} = useContext(dataContext);
  const [localArtist, setLocalArtist] = useState(artist);
  const [localPage, setLocalPage] = useState(page);
  
  useEffect(() => {
    setPage(localPage);
  }, [localPage]);

  useEffect(() => {
    // console.log(localArtist);
    setArtist(localArtist);
  }, [localArtist]);
  return (
    <Route path="/:lang?/:page?/:artist?" render={(match) => {
      const userLanguage = match['match']['params']['lang'];
      const userPage = match['match']['params']['page'];
      const artistPage = match['match']['params']['artist'];
      // console.log(localPage, userPage, localArtist, artistPage);
      // console.log(localPage !== userPage, localArtist !== artistPage);
      if (userLanguage && language !== userLanguage) {
        setLanguage(userLanguage);
      }
      document.documentElement.setAttribute('lang', userLanguage);
      if (localPage !== userPage || localArtist !== artistPage) {
        setLocalPage(userPage);
        setLocalArtist(artistPage);
      }
      // console.log(language);
      return <>
        <div id="background" />
        <Menu />
        <Switch>
          <Route path={routes.landing} render={getRenderPropForRoute('landing')} />
          <Route path={routes.artist} render={getRenderPropForRoute('artist')} />
          <Route path={routes.exercise} render={getRenderPropForRoute('exercise')} />
          <Route path={routes.article} render={getRenderPropForRoute('article')} />
          <Route path={routes.acknowledgement} render={getRenderPropForRoute('acknowledgement')} />
          <Route path={routes.press} render={getRenderPropForRoute('press')} />
          <Route path={routes.copyleft} render={getRenderPropForRoute('copyleft')} />
          <Route path={routes.archiveList} render={getRenderPropForRoute('archiveList')} />
          <Route path={routes.imageMap} render={getRenderPropForRoute('imageMap')} />
          <Redirect to={generatePath(routes.landing, {lang: language})} />
        </Switch>
        </>;
    }} />
  );
}

export default App;
