export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_POPUP_OPEN = 'SET_POPUP_OPEN';

export const SET_DATA_CAHCE = 'SET_DATA_CAHCE';
// export const GET_DATA_CAHCE = 'GET_DATA_CAHCE';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PAGE = 'SET_PAGE';

export const SET_ARTIST = 'SET_ARTIST';

export default (state, action) => {
  switch (action.type) {
    case SET_DATA_CAHCE:
      return {
        ...state,
        cache: new Map([...state.cache, action.payload])
      };
    // case GET_DATA_CAHCE:
    //   return {
    //     ...state,
    //     isPopupOpen: action.payload.isOpen,
    //     popupUrl: action.payload.url
    //   };
    case SET_LANGUAGE:
      console.log({
        ...state,
        language: action.payload.language
      })
      return {
        ...state,
        language: action.payload.language
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };
    case SET_ARTIST:
      return {
        ...state,
        artist: action.payload.artist
      };
    default:
      return state;
  }
};
