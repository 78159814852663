const routes = {
  landing: '/:lang/landing',
  artist: '/:lang/artist/:artist?',
  exercise: '/:lang/exercise/:artist?',
  acknowledgement: '/:lang/acknowledgement',
  press: '/:lang/press',
  copyleft: '/:lang/copyleft',
  article:'/:lang/article',
  archiveList: '/:lang/archiveList',
  imageMap: '/:lang/imageMap/:artist?',
  pageWithLang: (lang, page, artist) => `/${lang}/${page}${artist? `/${artist}`: ''}`
};

export default routes;