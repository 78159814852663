import React, { useReducer, useCallback, useContext } from 'react';
import DataContext from './dataContext';
import DataReducer, {SET_DATA_CAHCE, SET_LANGUAGE, SET_PAGE, SET_ARTIST} from './dataReducer';
import {apiBaseUrl} from 'globals/config';

import axios from 'axios';

const initialState = {
  cache: new Map(),
  language: 'zh',
  page: 'landing',
  artist: null
};

const DataState = ({ children }) => {
  const [state, dispatch] = useReducer(DataReducer, initialState);

  const getPage = useCallback(async (page) => {
    const lang = state.language;
    // const page = state.page;
    // const artist = state.artist;
    // console.log('getPage', state, lang, page, artist);
    if (state.cache.has(`${lang}/${page}`)) {
      return state.cache.get(`${lang}/${page}`);
    } else {
      // const newPageJson = await axios.get(`${apiBaseUrl}/${page}`); // fetchNewPage(page);
      const newPageJson = await axios.get(`${apiBaseUrl}/${lang}/${page}.json`); // fetchNewPage(page);
      dispatch({
        type: SET_DATA_CAHCE,
        payload: [
          `${lang}/${page}`, newPageJson.data
        ]
      })
      // dispatch({
      //   type: SET_LANGUAGE,
      //   payload: {
      //     language: lang
      //   }
      // })
      return newPageJson.data;
    }
  }, [state]);

  const setLanguage = useCallback(async lang => {
    dispatch({
      type: SET_LANGUAGE,
      payload: {
        language: lang
      }
    })
  }, [state]);

  const setPage = useCallback(async page => {
    dispatch({
      type: SET_PAGE,
      payload: {
        page: page
      }
    })
  }, [state]);

  const setArtist = useCallback(async artist => {
    dispatch({
      type: SET_ARTIST,
      payload: {
        artist: artist
      }
    })
  }, [state])
  return (
    <DataContext.Provider
      value={{
        ...state,
        getPage,
        setLanguage,
        setArtist,
        setPage
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataState;
