import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import DataState from 'contexts/data/dataState';
import './index.css';
import App from './App';

// import * as eruda from 'eruda';

ReactDOM.render(
  <DataState>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </DataState>, 
  document.getElementById('main')
);
// ReactDOM.hydrate(<App />, document.getElementById('main'));

// eruda.init();