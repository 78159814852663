import React, { useContext, useEffect, useRef, useState } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

import './menu.css';
import dataContext from 'contexts/data/dataContext';
import routes from 'globals/routes';

gsap.registerPlugin(ScrollToPlugin);

const Menu = () => {
  const {page, artist, language, getPage} = useContext(dataContext);
  const [content, setContent] = useState(null);
  const menuCoverEl = useRef(null);
  const setMenuCoverEl = (ref) => menuCoverEl.current = ref;
  const backToTop = (event) => {
    event.preventDefault();
    gsap.to(window, {
      scrollTo: 0
    });
  }
  const openMenu = () => {
    gsap.to(menuCoverEl.current, {
      autoAlpha: 1
    });
  }
  const closeMenu = () => {
    gsap.to(menuCoverEl.current, {
      autoAlpha: 0,
      onComplete: ()=> {
        document.querySelectorAll("#menu .items").forEach(e => e.classList.remove("selected"))
      }
    });
  }
  useEffect(() => {
    gsap.set(menuCoverEl.current, {
      autoAlpha: 0
    });
  }, []);
  useEffect(() => {
    (async _ => {
      const pageJson = await getPage('menuFULL');
      setContent(pageJson);
    })()
  }, [language]);
  // useEffect(() => {
  //   gsap.to(menuCoverEl.current, {
  //     autoAlpha: 0
  //   });
  // }, [page, hash]);

  return <div id="menu">
    <div className="rightTop">
      <div className="searchButton"></div>
      <div className="languageSwitch">
        <Link to={routes.pageWithLang('zh', page, artist)} className="tc" onClick={closeMenu}>繁</Link>
        <div className="seperator" />
        <Link to={routes.pageWithLang('en', page, artist)} className="en" onClick={closeMenu}>ENG</Link>
      </div>
      {/* menu logo*/}
      <div className="menuIcon" onClick={openMenu}>
        <img id="menuLogo" src="./media/images/menuIcon.svg" alt="menu" />
        <div className="mobileLine"></div>
      </div>
    </div>
    <div className="bottomLeft">
      <div className="help">
        <a href="mailto:visualdiaryarchive@gmail.com" target="_blank">            
          <img id="heloLogo" src="./media/images/enquiryIcon.svg" alt="help" />
        </a>
      </div>
      <div className="facebook">
        <a href="https://www.facebook.com/VisualDiaryArchive" target="_blank">
          <img id="FbLogo" src="./media/images/socialMediaFBIcon.svg" alt="FB" />
        </a>
      </div>
      <div className="instagram">
        <a href="https://www.instagram.com/visualdiaryarchive" target="_blank">
          <img id="IgLogo" src="./media/images/socialMediaIGIcon.svg" alt="IG" />
        </a>
      </div>
    </div>
    <a className="bottomRight" href="#" onClick={backToTop}>
      <div><img id="backTop" src="./media/images/narrow_sticker_up.png" alt="top" /></div>
      <div className="en" id="backTopText">TOP</div>
    </a>
    {content && <div className="menuCover" ref={setMenuCoverEl}>
      <div className="closeBtn" onClick={closeMenu}></div>
      <div className="desktop">
        <div className="title">{content['menu']['menuTitle']}</div>
        <Link to={generatePath(routes.landing, {lang: language}) } className="home" onClick={closeMenu}>{content['menu']['home']}</Link>
        <div className="tabs">
          <div className="tab tab1">
            <div className="header">{content['about']['aboutTitle']}</div>
            <div className="items">
              <Link to={generatePath(routes.landing, {lang: language}) + '#aboutProject'} className="item" onClick={closeMenu}>{content['about']['introduction']}</Link>
              <Link to={generatePath(routes.landing, {lang: language}) + '#aboutCreator'} className="item" onClick={closeMenu}>{content['about']['curators']}</Link>
              <Link to={generatePath(routes.article, {lang: language})} className="item" onClick={closeMenu}>{content['about']['article']}</Link>
              {/* <div className="item comingSoon">{content['about']['article']}<div>Coming Soon</div></div>   */}
            </div>
          </div>
          <div className="tab tab2">
            <div className="header">{content['other']['otherTitle']}</div>
            <div className="items">
              <Link to={generatePath(routes.acknowledgement, {lang: language})} className="item" onClick={closeMenu}>{content['other']['acknowledgement']}</Link>
              <Link to={generatePath(routes.press, {lang: language})} className="item" onClick={closeMenu}>{content['other']['press']}</Link>
              <Link to={generatePath(routes.copyleft, {lang: language})} className="item" onClick={closeMenu}>{content['other']['copyleft']}</Link>
            </div>
          </div>
          <div className="tab tab3">
            <div className="header">{content['visualArchive']['title']}</div>
            <div className="items">
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'kiwong'})} onClick={closeMenu}>{content['visualArchive']['archive1']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'lolailai'})} onClick={closeMenu}>{content['visualArchive']['archive2']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'elainewho'})} onClick={closeMenu}>{content['visualArchive']['archive3']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'ivymazasu'})} onClick={closeMenu}>{content['visualArchive']['archive4']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'wongchunhoi'})} onClick={closeMenu}>{content['visualArchive']['archive5']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'jasperfung'})} onClick={closeMenu}>{content['visualArchive']['archive6']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'ciciwu'})} onClick={closeMenu}>{content['visualArchive']['archive7']}</Link>
              <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'hosintung'})} onClick={closeMenu}>{content['visualArchive']['archive8']}</Link>
            </div>
          </div>
          <div className="tab tab4">
            <div className="header">{content['coCreate']['title']}</div>
            <div className="items">
              {content['coCreate']['exerciseSet'].map(artist => {
                return <div className="item" key={artist['artist']}>
                <div className="name">{artist['artistName']}</div>
                <div className="exercises">
                  {artist['exercises'].map((exercise, idx) => {
                    return <Link to={generatePath(routes.exercise, {lang: language, artist: `${artist['artist']}-${idx + 1}`})} className="exercise" onClick={closeMenu}>
                      {exercise}
                    </Link>;

                  })}
                </div>
              </div>
              })}
              
            </div>
          </div>
          <Link to={generatePath(routes.imageMap, { lang: language })} className="tab tab5" onClick={closeMenu}>{content['imageMap']['title']}</Link>
        </div>
      </div>
      <div className="mobile">
        <Link to={generatePath(routes.landing, {lang: language}) } className="header" onClick={closeMenu}>{content['menu']['home']}</Link>
        <div className="header" onClick={()=>document.querySelector(".itemsAbout").classList.add("selected")}>{content['about']['aboutTitle']}</div>
        <div className="header" onClick={()=>document.querySelector(".itemsVisualArchive").classList.add("selected")}>{content['visualArchive']['title']}</div>
        <div className="header" onClick={()=>document.querySelector(".itemsCoCreate").classList.add("selected")}>{content['coCreate']['title']}</div>
        <Link to={generatePath(routes.imageMap, { lang: language })} className="header" onClick={closeMenu}>{content['imageMap']['title']}</Link>
        <div className="items itemsAbout">
          <div className="backBtn" onClick={(e)=>e.currentTarget.parentElement.classList.remove("selected")} />
          <Link to={generatePath(routes.landing, {lang: language}) + '#aboutProject'} className="item" onClick={closeMenu}>{content['about']['introduction']}</Link>
          <Link to={generatePath(routes.landing, {lang: language}) + '#aboutCreator'} className="item" onClick={closeMenu}>{content['about']['curators']}</Link>
          <Link to={generatePath(routes.article, {lang: language})} className="item" onClick={closeMenu}>{content['about']['article']}</Link>
          <Link to={generatePath(routes.acknowledgement, {lang: language})} className="item" onClick={closeMenu}>{content['other']['acknowledgement']}</Link>
          <Link to={generatePath(routes.press, {lang: language})} className="item" onClick={closeMenu}>{content['other']['press']}</Link>
          <Link to={generatePath(routes.copyleft, {lang: language})} className="item" onClick={closeMenu}>{content['other']['copyleft']}</Link>
        </div>
        <div className="items itemsVisualArchive">
          <div className="backBtn" onClick={(e)=>e.currentTarget.parentElement.classList.remove("selected")} />
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'kiwong'})} onClick={closeMenu}>{content['visualArchive']['archive1']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'lolailai'})} onClick={closeMenu}>{content['visualArchive']['archive2']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'elainewho'})} onClick={closeMenu}>{content['visualArchive']['archive3']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'ivymazasu'})} onClick={closeMenu}>{content['visualArchive']['archive4']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'wongchunhoi'})} onClick={closeMenu}>{content['visualArchive']['archive5']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'jasperfung'})} onClick={closeMenu}>{content['visualArchive']['archive6']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'ciciwu'})} onClick={closeMenu}>{content['visualArchive']['archive7']}</Link>
          <Link className="item" to={generatePath(routes.artist, {lang: language, artist: 'hosintung'})} onClick={closeMenu}>{content['visualArchive']['archive8']}</Link>
        </div>
        <div className="items itemsCoCreate">
          <div className="backBtn" onClick={(e)=>e.currentTarget.parentElement.classList.remove("selected")} />
          <div className="exercisesWrapper">
            {content['coCreate']['exerciseSet'].map(artist => {
              return <div className="item" key={artist['artist']}>
              <div className="name">{artist['artistName']}</div>
              <div className="exercises">
                {artist['exercises'].map((exercise, idx) => {
                  return <Link to={generatePath(routes.exercise, {lang: language, artist: `${artist['artist']}-${idx + 1}`})} className="exercise" onClick={closeMenu}>
                    {exercise}
                  </Link>;
                })}
              </div>
            </div>
            })}
          </div>
        </div>
      </div>
    </div>}
  </div>
}

export default Menu;